/* General Reset for NavBar */
.navbar * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Navbar Container */
.navbar {
  background-color: #1e1e1e;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Navbar Navigation */
.navbar-nav {
  list-style: none;
  display: flex;
  justify-content: center;
}

/* Navbar Items */
.nav-item {
  margin: 0 1.5rem;
}

/* Navbar Links */
.nav-link {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.2rem;
  transition: color 0.3s ease-in-out;
}

.nav-link:hover {
  color: #00adb5;
}

/* Active Navbar Link */
.nav-link.active {
  border-bottom: 2px solid #00adb5;
}

/* Responsive Design for Navbar */
@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: column;
  }

  .nav-item {
    margin: 1rem 0;
  }
}
